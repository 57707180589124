import Oidc from 'oidc-client';
import logout from './logout';
import { IDENTITY_CONFIG as config } from './constants';

function handleErrors(response) {
    if (response.status === 401) {
        logout();
      }
    return response;
  }

export default (url, options = {}) => {
    const userManager = new Oidc.UserManager(config);
    return userManager.getUser().then(user => {
      if (!user) {
        userManager.signinRedirectCallback();
        return new Promise((s, f) => f('Not authenticated'));
      }
      options.headers.append('Authorization', `Bearer ${user.access_token}`);
      return fetch(url, options)
        .then(response => handleErrors(response))
        .then(handledResponse => new Promise(s => s(handledResponse)));
    });
  };