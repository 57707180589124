import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { CircularProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import api from '../../api/adaptor';
import Statistic from '../statistic';
import Moment from 'moment';

const productTabs = [
    {
        value: "Incidents",
        key: 0
    },
    {
        value: "Alerts",
        key: 1
    }
]

const getAlertColor = (alert) => {
        if(alert.overallState === 'Alert'){
            return 'red';
        } else if(alert.overallState === 'No Data'){
            return 'grey';
        } else if(alert.overallState === 'Warn'){
            return 'orange';
        } else if(alert.overallState === 'OK'){
            return 'green';
        } else{
            return 'blue';
        }
}

const getDatadogStatsBackground = (product) => {
    return (product.alerts.length > 0 
        ? (product.alerts.filter(x => x.overallState === 'Alert').length === 0
        ? (product.alerts.filter(x => x.overallState === 'Warn').length !== 0)
        ? 'amber-bg'
        : 'green-bg'
        : 'red-bg')
        : 'grey-bg')
}

const getDatadogStats = (product) => {
    if(product.alerts.length === 0){
        return <Typography variant="h4" className="kt-c-noDatadogAlerts">No Datadog Alerts</Typography>;
    }
    return(
        <table align="center" style={{width:'100%'}}>
            <tr>
                {
                    product.alerts.filter(x => x.overallState === 'OK').length > 0 &&
                    <td style={{fontSize:18,color:'white', width:'25%'}}>OK</td> 
                }
                {
                    product.alerts.filter(x => x.overallState === 'No Data').length > 0 &&
                    <td style={{fontSize:18,color:'white', width:'25%'}}>No Data</td>
                }
                {
                    product.alerts.filter(x => x.overallState === 'Warn').length > 0 &&
                    <td style={{fontSize:18,color:'white', width:'33%'}}>Warn</td>
                }
                {
                    product.alerts.filter(x => x.overallState === 'Alert').length > 0 &&
                    <td style={{fontSize:18,color:'white', width:'25%'}}>Alert</td>
                }
            </tr>
            <tr style={{height:18}}></tr>
            <tr>
                {
                    product.alerts.filter(x => x.overallState === 'OK').length > 0 &&
                    <td style={{color:'white'}}>
                        {
                            product.alerts.filter(x => x.overallState === 'OK').length > 0
                            ? product.alerts.filter(x => x.overallState === 'OK').length
                            : 0
                        }
                    </td>
                }
                {product.alerts.filter(x => x.overallState === 'No Data').length > 0 && <td style={{color:'white'}}>{product.alerts.filter(x => x.overallState === 'No Data').length > 0
                    ? product.alerts.filter(x => x.overallState === 'No Data').length : 0}</td>}
                {product.alerts.filter(x => x.overallState === 'Warn').length > 0 && <td style={{color:'white'}}>{product.alerts.filter(x => x.overallState === 'Warn').length > 0
                    ? product.alerts.filter(x => x.overallState === 'Warn').length : 0}</td>}
                {product.alerts.filter(x => x.overallState === 'Alert').length > 0 && <td style={{color:'white'}}>{product.alerts.filter(x => x.overallState === 'Alert').length > 0
                    ? product.alerts.filter(x => x.overallState === 'Alert').length : 0}</td>}
            </tr>
        </table>
    )
}

const Team = ({ match, history }) => {
    const name = match.params.name;
    const app = match.params.app;
    const section = match.params.section;
    const [team, setTeam] = useState(null);
    const isApp = team && team.products.filter(x => x.name === app).length === 1;
    const appIndex = team && isApp && team.products.findIndex(x => x.name === app);
    const isSection = productTabs && productTabs.filter(x => x.value === section).length === 1;
    const sectionIndex = isSection && productTabs.findIndex(x => x.value === section);
    const [activeProductTab, setActiveProductTab] = useState(0);
    const [activeOptionsTab, setActiveOptionsTab] = useState(0);
    const [launchInterval, setLaunchInterval] = useState(false);
    const fetchTeamDetail = async () => {
        setTeam(await api.getTeamDetail(name));
    };
    useEffect(() => {
        fetchTeamDetail();
        setLaunchInterval(true);
    }, []);
    if (launchInterval && team !== null && team.products.length > 1) {
        setTimeout(() => {
            const maxActiveProductTab = team.products.length - 1;
            if (activeProductTab < maxActiveProductTab) {
                setActiveProductTab(activeProductTab + 1);
            } else {
                setActiveProductTab(0);
            }
            fetchTeamDetail();
            setLaunchInterval(true);
        }, 60000);
        setLaunchInterval(false);
    } else if(launchInterval && team !== null && team.products.length === 1){
        setTimeout(() => {
            fetchTeamDetail();
            setLaunchInterval(true);
        }, 40000);
        setLaunchInterval(false);
    }
    const handleTabChangeProducts = (event, newValue) => setActiveProductTab(newValue);
    const handleTabChangeOptions = (event, newValue) => setActiveOptionsTab(newValue);
    return(
        <Container className="global-container">
            
            <Grid container>
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit">
                            K.U.R.T
                        </Link>
                        <Link color="inherit" onClick={() => history.push('/')}>
                            Teams
                        </Link>
                        <Typography color="textPrimary">{name}</Typography>
                    </Breadcrumbs>
                </Grid>

                <Grid item xs={12} className="animated fadeInUp">
                    <Typography variant="h3" gutterBottom>
                        {name}
                    </Typography>
                </Grid>
                {
                    !team &&
                    <CircularProgress style={{ margin: 'auto' }} />
                }

                {
                    team &&
                    <>
                        <Grid container spacing={3}>

                            <Grid item xs={12} className="animated fadeInUp">
                                <AppBar position="static" color="primary">
                                    <Tabs value={activeProductTab} onChange={handleTabChangeProducts} aria-label="simple tabs example">
                                        {
                                            team.products &&
                                            team.products.length > 0 &&
                                            team.products.map((product, index) => (
                                                <Tab key={index} label={product.name} id={`simple-tab-${index}`} aria-controls={`simple-tabpanel-${index}`} />
                                            ))
                                        }
                                    </Tabs>
                                </AppBar>
                            </Grid>
                        </Grid>
                        {
                            team.products &&
                            team.products.length > 0 &&
                            team.products.map((product, index) => {
                                Moment.locale('en');
                                var dt = product.lastReleasedOn;
                                var formattedDate = Moment(dt).format('Do MMMM YYYY @ h:mm a');
                                return (
                                    <Grid key={index} className="inner-container" container spacing={3} style={{ display: index !== activeProductTab ? 'none' : '' }}>
                                        <Grid item xs={12} className="animated fadeInUp">
                                        <Typography variant="h5">
                                            Last Released On - {product.lastReleasedOn === '' || product.lastReleasedOn === 'unknown' ? 'Unavaliable' : formattedDate}
                                        </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} className="animated fadeInUp">
                                            <Statistic
                                                title='Number of Incidents'
                                                description='The number of service-now incidents outstanding for this product'
                                                stat={product.incidents ? product.incidents.length : 0}
                                                background={'green-bg'}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4} className="animated fadeInUp">
                                            <Statistic
                                                title={<span>Number of Datadog Alerts</span>}
                                                stat={getDatadogStats(product)}
                                                background={getDatadogStatsBackground(product)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4} className="animated fadeInUp">
                                            <Statistic
                                                title={`Is ${product.name} Online?`}
                                                description='The result for the connectivity status of the application'
                                                stat='YES'
                                                background={'green-bg'}
                                            />
                                        </Grid>
                                        {
                                            team&&
                                            <AppBar position="static" color="primary">
                                                <Tabs value={activeOptionsTab} onChange={handleTabChangeOptions} aria-label="simple tabs example">
                                                    {
                                                        productTabs.map(tab => {
                                                            return(
                                                                <Tab key={tab.key} label={tab.value} id={`simple-tab-options-1`} aria-controls={`simple-tabpanel-1`} />
                                                            )
                                                        })
                                                    }
                                                </Tabs>
                                            </AppBar>
                                        }
                                        <Grid container xs={12} key={0} style={{ display: 0 !== activeOptionsTab ? 'none' : '' }}>
                                            {
                                                !product.incidents &&
                                                <p>Loading</p>
                                            }
                                            {
                                                product.incidents &&
                                            <>
                                                    <Grid item xs={12} className="animated fadeInUp" style={{paddingTop:'20px',paddingBottom:'20px'}}>
                                                        <Typography variant="h3">
                                                            Incidents
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} className="animated fadeInUp">
                                                        <Paper>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Number</TableCell>
                                                                        <TableCell>Title</TableCell>
                                                                        <TableCell align="right">Priority</TableCell>
                                                                        <TableCell>Last Updated By</TableCell>
                                                                        <TableCell>Opened At</TableCell>
                                                                        <TableCell>Created On</TableCell>
                                                                        <TableCell>Last Updated On</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {
                                                                        product.incidents &&
                                                                        product.incidents.length === 0 &&
                                                                        <TableRow>
                                                                            <TableCell component="th" scope="row" colSpan={7} align="center">No Incidents :)</TableCell>
                                                                        </TableRow>
                                                                    }
                                                                    {
                                                                        product.incidents &&
                                                                        product.incidents.length > 0 &&
                                                                        product.incidents.sort(x => x.priority).map(incident => (
                                                                            <TableRow key={incident.number}>
                                                                                <TableCell component="th" scope="row">
                                                                                    {incident.incidentNumber}
                                                                                </TableCell>
                                                                                <TableCell>{incident.title}</TableCell>
                                                                                <TableCell align="right">{incident.priority}</TableCell>
                                                                                <TableCell>{incident.updatedBy}</TableCell>
                                                                                <TableCell>{Moment(incident.openedAt).format('Do MMMM YYYY @ h:mm a')}</TableCell>
                                                                                <TableCell>{Moment(incident.createdOn).format('Do MMMM YYYY @ h:mm a')}</TableCell>
                                                                                <TableCell>{Moment(incident.lastUpdated).format('Do MMMM YYYY @ h:mm a')}</TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        </Paper>
                                                    </Grid>
                                                </>
                                                }
                                        </Grid>

                                        <Grid container xs={12} key={1} style={{ display: 1 !== activeOptionsTab ? 'none' : '' }}>
                                        {
                                                !product.alerts &&
                                                <p>Loading</p>
                                            }
                                            {
                                                product.alerts &&
                                            <>
                                                    <Grid item xs={12} className="animated fadeInUp" style={{paddingTop:'20px',paddingBottom:'20px'}}>
                                                        <Typography variant="h3">
                                                            Alerts
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} className="animated fadeInUp">
                                                        <Paper>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Name</TableCell>
                                                                        <TableCell>Overall State</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {
                                                                        product.alerts &&
                                                                        product.alerts.length === 0 &&
                                                                        <TableRow>
                                                                            <TableCell component="th" scope="row" colSpan={4} align="center">No Datadog Alerts :(</TableCell>
                                                                        </TableRow>
                                                                    }
                                                                    {
                                                                        product.alerts &&
                                                                        product.alerts.length > 0 &&
                                                                        product.alerts.map(alert => {
                                                                            return(
                                                                            <TableRow>
                                                                                <TableCell component="th" scope="row">
                                                                                    {alert.name}
                                                                                </TableCell>
                                                                            <TableCell><Grid item xs={1.5} align="left"><div align="center" style={{ padding: 2.5,width: '60px', margin: 5, backgroundColor: (getAlertColor(alert)), color: 'white', fontWeight: 'bolder', borderRadius: '5px' }}>{alert.overallState}</div></Grid></TableCell>
                                                                            </TableRow>
                                                                            )})
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        </Paper>
                                                    </Grid>
                                                </>
                                                }
                                        </Grid>
                                    </Grid>
                                );
                            })
                        }
                    </>
                }
            </Grid>
        </Container>
    )
}

export default withRouter(Team);