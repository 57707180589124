const redirectUri = `${window.location.protocol}//${window.location.host}/`;
const localAPI = false;
export const API_URL = process.env.NODE_ENV === 'production' ? '/graphql/' : localAPI ? 'https://localhost:5001/graphql/' : 'https://kurt-nonprod.gcp.retail.js-devops.co.uk/graphql/';
export const IDENTITY_CONFIG = {
    authority: 'https://login.retail.js-devops.co.uk',
    client_id: 'kurt',
    redirect_uri: redirectUri,
    response_type: 'id_token token',
    scope: 'openid profile email colleague.assignments',
    post_logout_redirect_uri: redirectUri,
  };