import React from 'react';
import { withRouter } from "react-router";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import scoreCalc from '../../../utils/scoreCalculator';

const TeamCard = ({ name, products, history }) => {
    return(
    <>
        <Card className={`elevation animated fadeInUp kt-c-teamCard`}>
            <CardContent>
                <Typography gutterBottom variant="h4" component="h2" style={{ paddingTop: 10, fontWeight: 'bold' }}>
                    {name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    <ol>
                        {
                            products &&
                            products.length > 0 &&
                            products.map(product => {
                                const result = scoreCalc(product);
                                return(
                                    <li>
                                        <Grid container spacing={1}>
                                            <Grid item align="center" style={{ lineHeight: 2.5, fontSize: 15, fontWeight: 'bolder' }}>{product.name}</Grid>
                                            <Grid item xs={1.5} align="left"><div align="center" style={{ padding: 2.5, margin: 5, backgroundColor: (result.scoreCalc.color), width: '100%', color: 'white', fontWeight: 'bolder', borderRadius: '5px' }}>{result.scoreCalc.score !== 666 && result.scoreCalc.score !== 0 ? result.scoreCalc.score.toFixed(2) + '%' : result.scoreCalc.status}</div></Grid>
                                        </Grid>
                                    </li>
                                )})
                        }
                    </ol>
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="primary" onClick={() => history.push(`/team/${name}`)}>
                    View
                </Button>
            </CardActions>
        </Card>
    </>
    );
};

export default withRouter(TeamCard);