import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import Oidc from 'oidc-client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { IDENTITY_CONFIG as config } from './utils/constants';

const render = user => {
    ReactDOM.render(<App currentUser={user} />,document.getElementById('root'));
    };
    
    const mgr = new Oidc.UserManager(config);
    
    if (window.location.href.indexOf('#id_token') >= 0) {
      mgr.signinRedirectCallback().then(user => {
        window.location.href = user.state.redirectUrl;
      });
    } else if (window.location.pathname.startsWith('/view')) {
      render("TV User")
    } else {
      mgr.getUser().then(user => {
        if (user) {
          render(user);
        } else {
          mgr.signinRedirect({ state: {
            redirectUrl: window.location.href
          } });
        }
      });
    
    }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
