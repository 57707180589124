import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Overview from './components/overview';
import Header from './components/header';
import Team from './components/team';
import Leaderboard from './components/leaderboard';
import TvView from './components/tvView';
//import Error404 from './components/error404';
import Logout from './utils/logout';
import GoogleAnalytics from './components/googleAnalytics/googleAnalytics';

const App = ({ currentUser }) => (
  <Router>
    <div>
      <nav>
        <Header currentUser={currentUser} />
      </nav>
      <Switch>
        <Route path="/" exact component={Overview} />
        <Route path="/leaderboard" component={Leaderboard} />
        <Route path="/team/:name" exact component={Team} />
        <Route path="/team/:name/:app" exact component={Team} />
        <Route path="/team/:name/:app/:section" component={Team} />
        {
          <Route path="/view" component={TvView} />
        }
        {
        //<Route path="/" component={Error404} />
        }
        <Route path="/logout" component={Logout} />
      </Switch>
    </div>
    <GoogleAnalytics />
  </Router>
);

export default App;