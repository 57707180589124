import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const Statistic = ({ title, description, stat, background }) => {
    const haveBackground = background !== null;
    return (
        <Card className={`elevation ${haveBackground ? background : ''}`}>
            <CardContent style={{ textAlign: 'center' }}>
                <Typography color={`${!haveBackground && 'textPrimary'}`} gutterBottom>
                    {title}
                </Typography>
                <Typography variant="h3">
                    {stat}
                </Typography>
                <Typography color={`${!haveBackground && 'textSecondary'}`}>
                    {description}
                </Typography>
            </CardContent>
        </Card>
    )
};

export default Statistic;
