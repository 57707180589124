const scoreCalc = product => {
    const data = {
        scoreCalc: {
            errors: 0,
            oks: 0,
            noDatas: 0,
            totalErrors: 0,
            score: 0,
            color: 'grey',
            status: 'Good',
            alertCount: 0
        }
    };

    if(product.alerts.filter(x => x.overallState === 'Alert').length === 0){
        data.scoreCalc.errors = 0;
    } else{
        data.scoreCalc.errors = product.alerts.filter(x => x.overallState === 'Alert').length;
        data.scoreCalc.alertCount += product.alerts.filter(x => x.overallState === 'Alert').length;
    } if(product.alerts.filter(x => x.overallState === 'OK').length === 0){
        data.scoreCalc.oks = 0;
    } else{
        data.scoreCalc.oks = product.alerts.filter(x => x.overallState === 'OK').length;
        data.scoreCalc.alertCount += product.alerts.filter(x => x.overallState === 'OK').length;
    } if(product.alerts.filter(x => x.overallState === 'No Data').length === 0){
        data.scoreCalc.noDatas = 0;
    } else{
        data.scoreCalc.noDatas = product.alerts.filter(x => x.overallState === 'No Data').length;
        data.scoreCalc.alertCount += product.alerts.filter(x => x.overallState === 'No Data').length;
    }

    data.scoreCalc.totalErrors = (data.scoreCalc.errors);
    data.scoreCalc.score = data.scoreCalc.alertCount === 0
    ? 0
    : (100 - (data.scoreCalc.totalErrors / data.scoreCalc.alertCount) * 100);

    if(data.scoreCalc.score === 0) {
        data.scoreCalc.color = 'grey';
        data.scoreCalc.status = 'MISSING';
    } else if(product.alerts.filter(x => (x.overallState === 'Alert'.length) > 0) && data.scoreCalc.totalErrors > 0){
        data.scoreCalc.color = 'red';
        data.scoreCalc.status = 'URGENT';
        data.scoreCalc.score = 666;
    }else if(data.scoreCalc.score > 0 && data.scoreCalc.score < 33){
        data.scoreCalc.color = 'red';
        data.scoreCalc.status = 'URGENT';
        data.scoreCalc.score = 666;
    } else if(data.scoreCalc.score >= 33 && data.scoreCalc.score < 66){
        data.scoreCalc.color = 'orange';
        data.scoreCalc.status = 'WARNING';
    } else{
        data.scoreCalc.color = 'green';
        data.scoreCalc.status = 'OK';
    }
    return data;
}

export default scoreCalc;