import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import api from '../../api/adaptor';
import TeamCard from './teamCard';
import { CircularProgress } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import scoreCalc from '../../utils/scoreCalculator';

const Overview = () => {
    const [teams, setTeams] = useState(null);
    useEffect(() => {
        const fetchTeams = async () => {
            setTeams(await api.getTeams());
        };
        fetchTeams();
    }, []);
    let teamsWithScores = [];
    const getSortedTeams = (teams) => {
            teams.forEach(function(team) {
                let numberOfProducts = team.products.length;
                let totalScore = 0;
                let urgentProduct = false;
                if(team.products.length === 0){
                    totalScore = 100
                } else {
                team.products.forEach(function(product) {
                    let result = scoreCalc(product);
                    if(result.scoreCalc.score !== 666){
                        totalScore+=result.scoreCalc.score;
                    } else{
                        urgentProduct = true;
                    }
                });
            }
                let teamScore = Math.round(totalScore/numberOfProducts)

                if(urgentProduct){
                    teamScore = 0;
                }
        
                let teamWithScore = {team: team, score: teamScore};
        
                teamsWithScores.push(teamWithScore);
            });
            teamsWithScores.sort(function(a, b) { 
                return(a.score - b.score);
            });   
            return teamsWithScores;   
    }
    return(
        <>
            <Container className="global-container">
            <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link className={'kt-c-breadcrumbLink'} to={'/'}>
                                K.U.R.T
                            </Link>
                            <Typography color="textPrimary">Overview</Typography>
                        </Breadcrumbs>
                    </Grid>  
                <Grid container spacing={3}>
     
                    <Grid item xs={12} className="animated fadeInUp">
                        <Typography variant="h3">
                            Overview
                        </Typography>
                    </Grid>
                    {
                        !teams &&
                        <CircularProgress style={{ margin: 'auto' }} />
                    }
                    {
                        teams &&
                        teams.length > 0 &&
                        getSortedTeams(teams).map(team => (
                            <Grid item xs={12} sm={6} md={4}>
                                <TeamCard
                                    name={team.team.name}
                                    products={team.team.products}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
        </>
    )
}

export default Overview;