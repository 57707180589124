import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 240,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '50%',
      textAlign:'left'
    },
  }));

const TvView = ({ match, history }) => {
    const Teams = [
      {
        name: "Thunderbird"
      },
      {
        name: "Rocket"
      },
      {
        name: "Polaris"
      },
      {
        name: "Cobra"
      }
    ]
    const [TeamNames,setTeamNames] = React.useState(Teams)
    const updateTeamSearch = (e) => {
      setTeamNames(Teams.filter(x => x.name.toLowerCase().includes(e.target.value.toLowerCase())))
    }
    const classes = useStyles();
    return(
        <div width="100%" align="center">
        <h1>Search Teams</h1>
        <div align="center">
          <form className={classes.container} noValidate autoComplete="off">
              <TextField
                id="outlined-basic"
                className={classes.textField}
                label="Team Name"
                margin="normal"
                variant="outlined"
                onChange={updateTeamSearch}
              />
          </form>
        </div>
        <div>
          {TeamNames.map(team => <p>{team.name}</p>)}
        </div>
      </div>
    )
}

export default TvView;