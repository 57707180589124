import React from 'react';
import clsx from 'clsx';
import {withRouter} from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import isMobile from '../../utils/isMobile';


const drawerWidth = "100%";

const links = () => ([
    {
        title: 'Overview',
        link: '/'
    },
    {
        title: 'Leaderboard',
        link: '/leaderboard'
    }
]);

const useStyles = makeStyles(theme => ({
    lineHeight: '100px',
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
        lineHeight: '58px',
        paddingRight: '10px'
    },
    headerItem: {
        color: 'white',
        margin: '10px',
    },
    headerLeft: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        lineHeight: '36px'
    },
    accountIcon: {
        paddingRight:'7.5px'
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      hide: {
        display: 'none',
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '15px',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'space-between',
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
      },
      contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
      mobileSubMenuItem: {
          paddingLeft: '75px'
      },
      display: {
      },
      displayNone: {
          display: 'none'
      },
      linkNoStyle: {
          textDecoration: 'none',
          color: 'inherit'
      }
  }));

const Header = ({ history, currentUser }) => {
    const pageLinks = links();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [drawerAccountLinks, setDrawerAccountLinks] = React.useState(false);
    const isTvUser = currentUser === "TV User";
    const profileUser = !isTvUser ? currentUser.profile.name : "TV User";

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerLinks = () => {
      !drawerAccountLinks && !isTvUser ? setDrawerAccountLinks(true) : setDrawerAccountLinks(false);
    }

    return (
        <div className={classes.root}>
            <AppBar
                position="static"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: openDrawer,
                })}
            >
            <Toolbar>
                <div className={classes.headerLeft}>
                    {
                    isMobile &&
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    }
                <Typography variant="h6" component="h1" className={classes.title}>
                    <Link to={isTvUser ? '/view' : '/'}>K.U.R.T</Link>
                </Typography>
                <div className={isMobile || isTvUser ? classes.displayNone : classes.display}>
                    <Link to={'/'}>
                        <Button className={classes.headerItem}>
                            Overview
                        </Button>
                    </Link>
                    <Link to={'/leaderboard'}>
                        <Button className={classes.headerItem}>
                            Leaderboard
                        </Button>
                    </Link>
                </div>
                </div>
                <div className={!isMobile ? classes.display : classes.displayNone}>
                  <Button
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={!isTvUser ? handleMenu : null}
                    color="inherit"
                  >
                    <AccountCircle className={classes.accountIcon}/><Typography variant="button">{profileUser}</Typography>
                  </Button>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <Link to={'/logout'} style={{textDecoration:'none',color:'inherit'}}><MenuItem onClick={handleClose}>Logout</MenuItem></Link>
                  </Menu>
                </div>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={openDrawer}
            classes={{
            paper: classes.drawerPaper,
            }}
        >
            
            <div className={classes.drawerHeader} onClick={handleDrawerClose}>
              <Link to={isTvUser ? '/view': '/'}>
                <div width={'100%'}>
                    <Typography variant="subtitle1">K.U.R.T</Typography>
                </div>
              </Link>
              <div>
                <IconButton>
                    <ChevronLeftIcon />
                </IconButton>
              </div>
            </div>
            
            <Divider />
            <List className={isTvUser ? classes.displayNone : classes.display}>
            {pageLinks.map((text, index) => (
                <ListItem button key={index}>
                <Link to={text.link} onClick={() => handleDrawerClose()} className={classes.linkNoStyle}><ListItemText primary={text.title} /></Link>
                </ListItem>
            ))}
            </List>
            <Divider />
            <List>
                <ListItem button key={5} onClick={handleDrawerLinks}>
                    <ListItemIcon><AccountCircle/></ListItemIcon>
                    <ListItemText primary={profileUser} />
                </ListItem>
                <ListItem button key={6} className={drawerAccountLinks ? classes.display : classes.displayNone}>
                    <ListItemText className={classes.mobileSubMenuItem} primary={'Profile'} />
                </ListItem>
                <ListItem button key={7} className={drawerAccountLinks ? classes.display : classes.displayNone}>
                    <Link to={'/logout'} style={{textDecoration:'none',color:'inherit'}} onClick={() => handleDrawerClose()} ><ListItemText className={classes.mobileSubMenuItem} primary={'Logout'} /></Link>
                </ListItem>
            </List>
        </Drawer>
        </div>
      );
}

export default withRouter(Header);