import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

const LeaderboardItem = ({ team }) => {
   
    let numberOfProducts = team.team.products.length;
    
    let teamScore = team.score;

    let backgroundColour = 'red-bg';

    if(teamScore >= 66){
        backgroundColour = 'green-bg';
    }
    else if(teamScore>= 33 && teamScore < 66) {
        backgroundColour = 'amber-bg';
    }    

    return (
        <Link to={`/team/${team.team.name}`}>
            <Card className={`elevation ${backgroundColour}`}>
                <CardContent>
                <Grid container spacing={2}>
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                    <Typography variant="h4">
                        {team.team.name}
                    </Typography>
                    <Typography variant="h5">
                        Products: {numberOfProducts}
                    </Typography>
                    </Grid>                    
                    </Grid>
                    <Grid item>
                    <Typography variant="h4">
                        Score: {teamScore}
                    </Typography>
                    </Grid>
                </Grid>
                </Grid>
                </CardContent>
            </Card>
        </Link>
    )
};

export default LeaderboardItem;
