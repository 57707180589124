import { API_URL } from "../utils/constants";
import fetchAuth from '../utils/fetchAuth';

export default {
    getTeams: async () => {
        try {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            const response = await fetchAuth(`${API_URL}`, {
                method: 'POST',
                headers,
                body: JSON.stringify({
                    "query": "query { teams { name products { name lastReleasedOn alerts { name overallState } } } }"
                })
            });
            if (response.status === 200) {
                return (await response.json()).data.teams;
            }
            throw response.statusText;
        } catch (err) {
            throw new Error(err);
        }
    },
    getTeamDetail: async team => {
        try {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            const response = await fetchAuth(`${API_URL}`, {
                method: 'POST',
                headers,
                body: JSON.stringify({
                    'query': `query { team(name:"${team}") { name products { name lastReleasedOn alerts { name overallState } incidents { incidentNumber title priority state impact updatedBy lastUpdated createdOn openedAt } status { up uptime } } } }`
                })
            });
            if (response.status === 200) {
                return (await response.json()).data.team[0];
            }
            throw response.statusText;
        } catch (err) {
            throw new Error(err);
        }
    },
    getLeaderboard: async () => {
        try {
            await sleep(2000);
            const response = {
                status: 200,
                statusText: 'done',
                leaderboard: {
                    teams: [
                        {
                            id: 1,
                            name: 'Rocket',
                            score: 75
                        },
                        {
                            id: 2,
                            name: 'Thunderbird',
                            score: 95
                        },
                        {
                            id: 33,
                            name: 'Polaris',
                            score: 80
                        },
                        {
                            id: 4,
                            name: 'Hunter',
                            score: 55
                        }
                    ]
                }
            };
            if (response.status === 200) {
                return response.leaderboard;
            }
            throw response.statusText;
        } catch (err) {
            throw new Error(err);
        }
    }
};

function sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  