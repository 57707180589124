import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import api from '../../api/adaptor';
import LeaderboardItem from './leaderboardItem';
import scoreCalc from '../../utils/scoreCalculator';

const Leaderboard = ({ match, history }) => {
    const [teams, getTeams] = useState([]);
    useEffect(() => {
        const fetchTeams = async () => {
            getTeams(await api.getTeams());
        };
        fetchTeams();
    }, []);

    let teamsWithScores  = [];

    teams.forEach(function(team) {
        let numberOfProducts = team.products.length;
        let totalScore = 0;
        let teamScore = 0;
        if(team.products.length !== 0){
            team.products.forEach(function(product) {
                let result = scoreCalc(product);
                if(result.scoreCalc.score !== 666){
                    totalScore+=result.scoreCalc.score;
                }
            teamScore = Math.round(totalScore/numberOfProducts)
        });
        }

        let teamWithScore = {team: team, score: teamScore};

        teamsWithScores.push(teamWithScore);
    });

    teamsWithScores.sort(function(a, b) { 
        return(b.score - a.score);
        }        
    );
    return (
        <>
            <Container className="global-container">
                <Grid container>
                    <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link className={'kt-c-breadcrumbLink'} to={'/'}>
                                K.U.R.T
                            </Link>
                            <Typography color="textPrimary">Leaderboard</Typography>
                        </Breadcrumbs>
                    </Grid>                    
                    <Grid container spacing={3}>
                        <Grid item xs={12} className="animated fadeInUp">
                            <Typography variant="h3">
                                Leaderboard
                            </Typography>
                        </Grid>
                    </Grid>
                        {
                         teamsWithScores &&
                         teamsWithScores.length === 0 &&
                         <>
                         <CircularProgress style={{ margin: 'auto' }} />
                         </>
                        }
                        {
                            teamsWithScores &&
                            teamsWithScores.length > 0 &&
                            teamsWithScores.map((team, index) => (
                            <>  
                                <Grid item xs={12} className="inner-container" >
                                    <LeaderboardItem
                                        team = {team}
                                    />
                                </Grid>
                            </>  
                            ))
                        }
                    </Grid>
                </Container>
            </>
    )
};

export default withRouter(Leaderboard);